import * as style from './style.module.scss'
import React from 'react'

export function CustomReservationMessage(props) {
  return (
    <div className={style.customReservationMessage}>
      {/* <div className={style.close}>x</div> */}
      <div className={style.titleDateContainer}>
        <div className={style.title}>Reservation request</div>
        <div className={style.date}>
          {/* {JSON.stringify(props, undefined, 2)} */}
          {`${new Date(props.startDate).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
          })} - ${new Date(props.endDate).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
          })}`}
        </div>
      </div>
      <div className={style.price}>$498</div>
      <div className={style.container}>
        <div className={style.dog}>Gotti</div>
        <div className={style.dog}>Capone</div>
      </div>
    </div>
  )
}
